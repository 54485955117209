import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyC-Y8aXwIjpyO-OyoPeJdku07f2L8AKwuQ",
  authDomain: "venn-prod-70222.firebaseapp.com",
  databaseURL: "https://venn-prod-70222.firebaseio.com",
  projectId: "venn-prod-70222",
  storageBucket: "venn-prod-70222.appspot.com",
  messagingSenderId: "549725479573",
  appId: "1:1:549725479573:web:59c24f2e0b1a6b71ed319c",
  measurementId: "G-X530N0B366",
});

function render() {
  return ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
