import React from "react";
import "./App.css";
import { Container, Row, Col } from "react-grid-system";
import firebase from "firebase";
import moment from "moment";
import currencyFormatter from "currency-formatter";

type AppProps = {};

type AppState = {
  todaySummary: any;
  yesterdaySummary: any;
  lastWeekSummary: any;
  thisMonthSummary: any;
  lastMonthSummary: any;
  lifetimeSummary: any;
  firebaseClient: firebase.app.App | undefined;
};

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      todaySummary: undefined,
      yesterdaySummary: undefined,
      lastWeekSummary: undefined,
      thisMonthSummary: undefined,
      lastMonthSummary: undefined,
      lifetimeSummary: undefined,
      firebaseClient: undefined,
    };
  }

  async componentDidMount() {
    const db = firebase.firestore();
    db.collection("analytics")
      .doc("summaries")
      .collection("orders")
      .doc(moment().format("YYYY-MM-DD"))
      .onSnapshot((doc) => {
        this.setState({ todaySummary: doc.data() });
      });

    db.collection("analytics")
      .doc("summaries")
      .collection("orders")
      .doc(moment().subtract("day", 1).format("YYYY-MM-DD"))
      .onSnapshot((doc) => {
        this.setState({ yesterdaySummary: doc.data() });
      });

    db.collection("analytics")
      .doc("summaries")
      .collection("orders")
      .doc(moment().subtract("day", 7).format("YYYY-MM-DD"))
      .onSnapshot((doc) => {
        this.setState({ lastWeekSummary: doc.data() });
      });

    db.collection("analytics")
      .doc("monthlySummaries")
      .collection("orders")
      .doc(moment().format("YYYY-MM"))
      .onSnapshot((doc) => {
        this.setState({ thisMonthSummary: doc.data() });
      });

    db.collection("analytics")
      .doc("monthlySummaries")
      .collection("orders")
      .doc(moment().subtract(1, "month").format("YYYY-MM"))
      .onSnapshot((doc) => {
        this.setState({ lastMonthSummary: doc.data() });
      });

    db.collection("analytics")
      .doc("totalSummaries")
      .onSnapshot((doc) => {
        this.setState({ lifetimeSummary: doc.data() });
      });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div className="logo">
              <img
                src="./images/logo.png"
                width="20%"
                alt="Venn Apps Logo"
              ></img>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className="dashboardTitle">Today</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardContent">
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Total</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.todaySummary?.gbpTotal || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Orders</Col>
                    <Col className="dashboardContentValue">
                      {new Intl.NumberFormat("en-GB").format(
                        this.state.todaySummary?.numberOfOrders || 0
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">AOV</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.todaySummary?.gbpAverageOrderValue || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className="dashboardTitle">Yesterday</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardContent">
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Total</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.yesterdaySummary?.gbpTotal || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Orders</Col>
                    <Col className="dashboardContentValue">
                      {new Intl.NumberFormat("en-GB").format(
                        this.state.yesterdaySummary?.numberOfOrders || 0
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">AOV</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.yesterdaySummary?.gbpAverageOrderValue || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className="dashboardTitle">7-days ago</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardContent">
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Total</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.lastWeekSummary?.gbpTotal || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Orders</Col>
                    <Col className="dashboardContentValue">
                      {new Intl.NumberFormat("en-GB").format(
                        this.state.lastWeekSummary?.numberOfOrders || 0
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">AOV</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.lastWeekSummary?.gbpAverageOrderValue || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className="dashboardTitle">This Month</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardContent">
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Total</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.thisMonthSummary?.gbpTotal || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Orders</Col>
                    <Col className="dashboardContentValue">
                      {new Intl.NumberFormat("en-GB").format(
                        this.state.thisMonthSummary?.numberOfOrders || 0
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">AOV</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.thisMonthSummary?.gbpAverageOrderValue || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className="dashboardTitle">Last Month</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardContent">
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Total</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.lastMonthSummary?.gbpTotal || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Orders</Col>
                    <Col className="dashboardContentValue">
                      {new Intl.NumberFormat("en-GB").format(
                        this.state.lastMonthSummary?.numberOfOrders || 0
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">AOV</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.lastMonthSummary?.gbpAverageOrderValue || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className="dashboardTitle">Lifetime</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardContent">
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Total</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.lifetimeSummary?.gbpTotal || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">Orders</Col>
                    <Col className="dashboardContentValue">
                      {new Intl.NumberFormat("en-GB").format(
                        this.state.lifetimeSummary?.numberOfOrders || 0
                      )}
                    </Col>
                  </Row>
                  <Row className="dashboardContentRow">
                    <Col className="dashboardContentTitle">AOV</Col>
                    <Col className="dashboardContentValue">
                      {currencyFormatter.format(
                        this.state.lifetimeSummary?.gbpAverageOrderValue || 0,
                        { code: "GBP" }
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
